import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import bookdriveLogo from "./../../../assets/img/BookDrive_logo-invert.jpg";
import { Dropdown } from "antd";
import PricingTable from "./PlanCard";
import "../../../assets/css/Membership.scss";

const Membership = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Use URLSearchParams to parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const role = queryParams.get("instructorId"); // Get the 'Role' query parameter
    
    const items = [
        {
            label: (
                <p
                    className="boldText"
                    style={{ padding: ".5rem 1rem" }}
                    onClick={() => navigate("/auth/?Role=STUDENT")}
                >
                    Student
                </p>
            ),
            key: "0",
        },
        {
            label: (
                <p
                    className="boldText"
                    style={{ padding: ".5rem 1rem" }}
                    onClick={() => navigate("/auth/?Role=DRIVING_SCHOOL")}
                >
                    School
                </p>
            ),
            key: "1",
        },
        {
            label: (
                <p
                    className="boldText"
                    style={{ padding: ".5rem 1rem" }}
                    onClick={() => navigate("/auth/?Role=BOOK_DRIVE")}
                >
                    Admin
                </p>
            ),
            key: "2",
        },
    ];
    return (
        <div className="membership_container">
            <div
                className="header_membership"
            >
                <div className="fp_page" style={{ overflowX: "hidden" }}>
                    <div className="fp_header" style={{ overflowX: "hidden" }}>
                        <img src={bookdriveLogo} alt="" />
                        <Dropdown
                            menu={{
                                items,
                            }}
                            trigger={["click"]}
                            className="loginButton boldText"
                        >
                            <button onClick={(e) => e.preventDefault()}>Login As</button>
                        </Dropdown>
                    </div>

                </div>
            </div>

            <div className="membership_content">
                <div className="pricing_table">
                    <PricingTable id={role}/>
                </div>
            </div>
        </div>
    );
};

export default Membership;
