import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import bookdriveLogo from "../../assets/img/BookDrive_logo-invert.jpg";
import { Dropdown } from "antd";
import "../../assets/css/SuccessPage.scss";
import CancelCross from "../../assets/img/cancel-cross.svg";

const CancelPage = () => {
    const navigate = useNavigate();
    // const location = useLocation();

    // Use URLSearchParams to parse query parameters
    // const queryParams = new URLSearchParams(location.search);
    // const role = queryParams.get("instructorId"); // Get the 'Role' query parameter

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const checkIsMobile = () => {
        const mobileCheck = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth < 768;
        setIsMobile(mobileCheck);
    };

    useEffect(() => {
        window.addEventListener("resize", checkIsMobile);
        return () => {
            window.removeEventListener("resize", checkIsMobile);
        };
    }, []);

    const items = [
        {
            label: (
                <p
                    className="boldText"
                    style={{ padding: ".5rem 1rem" }}
                    onClick={() => navigate("/auth/?Role=STUDENT")}
                >
                    Student
                </p>
            ),
            key: "0",
        },
        {
            label: (
                <p
                    className="boldText"
                    style={{ padding: ".5rem 1rem" }}
                    onClick={() => navigate("/auth/?Role=DRIVING_SCHOOL")}
                >
                    School
                </p>
            ),
            key: "1",
        },
        {
            label: (
                <p
                    className="boldText"
                    style={{ padding: ".5rem 1rem" }}
                    onClick={() => navigate("/auth/?Role=BOOK_DRIVE")}
                >
                    Admin
                </p>
            ),
            key: "2",
        },
    ];
    return (
        <div className="success_container">
            <div
                className="header_success"
            >
                <div className="fp_page" style={{ overflowX: "hidden" }}>
                    <div className="fp_header" style={{ overflowX: "hidden" }}>
                        <img src={bookdriveLogo} alt="" />
                        <Dropdown
                            menu={{
                                items,
                            }}
                            trigger={["click"]}
                            className="loginButton boldText"
                        >
                            <button onClick={(e) => e.preventDefault()}>Login As</button>
                        </Dropdown>
                    </div>

                </div>
            </div>

            <div className="success_content">
                <div 
                className="box--div"
                >
                    <img src={CancelCross} alt="" />
                    <h1>Cancelled</h1>
                    <p>Your subscription has been cancelled.</p>
                    <a href={isMobile ? "/createClient" : "/"} className="button">Back to BookDrive</a>
                </div>
            </div>
        </div>
    );
};

export default CancelPage;
