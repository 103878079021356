import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import axios from "axios";
const handleClick = (subscriptionType, instructorId, successUrl, cancelUrl) => {
    // Prepare the data payload
    const data = JSON.stringify({
        subscriptionType: subscriptionType, // String, e.g., "yearly"
        instructorId: instructorId,         // String, e.g., "cf1c15e9-7e68-477b-bcc5-fadd554f05c0"
        successUrl: successUrl,             // Redirect URL on success
        cancelUrl: cancelUrl,               // Redirect URL on cancel
    });

    // Axios configuration
    const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/stripeTransaction/instructor/create-session`,
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    };

    axios
        .request(config)
        .then((response) => {
            const paymentUrl = response?.data?.stripePaymentUrl; // Extract the payment URL

            if (paymentUrl) {
                // Try opening the pop-up window
                const popup = window.open(paymentUrl, '_blank');

                // If pop-up was blocked, fallback to redirecting the user to the URL
                if (!popup || popup.closed || typeof popup.closed === 'undefined') {
                    console.log("Pop-up blocked, redirecting user...");
                    window.location.href = paymentUrl;
                }
            } else {
                console.error("Error: Payment URL not found in the response");
                alert("Failed to create a session. Please try again.");
            }
        })
        .catch((error) => {
            console.error("Error creating session:", error.response?.data || error.message);
            alert("Failed to create a session. Please try again.");
        });
};



const PlanCard = ({ title, amount, period, features, buttonText, isHighlighted, id, disabled }) => {
    let instructorId = id
    let subscriptionType = period.toLowerCase()
    let successUrl = "https://bookdrive.co.uk/membership"
    let cancelUrl = "https://bookdrive.co.uk/membership"
    
    return (
        <div
            className={`price--table--item ${isHighlighted ? "highlight" : ""
                }`}
        >
            <h2 className="heading--price">{title}</h2>
            <p className="price--plan">
                <span className="price--amount">£{amount}</span> / {" "}{period}</p>
            <button
                className={`button ${isHighlighted ? "highlight--btn" : ""
                    } ${disabled ? "disabled--btn" : ''}`}
                onClick={() => handleClick( subscriptionType, instructorId, successUrl, cancelUrl )}
                disabled={disabled ? true : false}
            >
                {disabled ? 'Currently Enrolled' : buttonText}
            </button>
            <ul className="lists--plan">
                {features.map((feature, index) => (
                    <li
                        key={index}
                        className={`list--plan--items ${feature.included ? isHighlighted ? "white-highlight" : "" : ""
                            }`}
                    >
                        <span>{feature.included ? <CheckCircleIcon /> : <CancelIcon />}</span>
                        {feature.name}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const PricingTable = ({id}) => {    
    const plans = [
        {
            title: "Basic free",
            amount: "0",
            period: "Monthly",
            features: [
                { name: "Dashboard Insights", included: true },
                { name: "Client & Diary Management", included: true },
                { name: "Cancellations and Notifications", included: true },
                { name: "Max upto 3 Clients", included: true },
                { name: "Tech Support", included: true },
            ],
            buttonText: "Pay Now",
            isHighlighted: false,
            disabled: true,
        },
        {
            title: "Premium",
            amount: "10",
            period: "Monthly",
            features: [
                { name: "Basic Plan plus", included: true },
                { name: "Unlimited Clients", included: true },
                { name: "Reporting Analytics", included: true },
                { name: "Solo & Multi - Instructor users", included: true },
                { name: "Driving School Admin", included: true },
                { name: "Delegate access", included: true },
                { name: "24 x 7 Priority Support", included: true },
            ],
            buttonText: "Pay Now",
            isHighlighted: true,
            disabled: false,
        },
        {
            title: "Premium",
            amount: "90",
            period: "Yearly",
            features: [
                { name: "Basic Plan plus", included: true },
                { name: "Unlimited Clients", included: true },
                { name: "Reporting Analytics", included: true },
                { name: "Solo & Multi - Instructor users", included: true },
                { name: "Driving School Admin", included: true },
                { name: "Delegate access", included: true },
                { name: "24 x 7 Priority Support", included: true },
            ],
            buttonText: "Pay Now",
            isHighlighted: false,
            disabled: false,
        },
    ];

    return (
        <>
            {plans.map((plan, index) => (
                <PlanCard key={index} {...plan} id={id}/>
            ))}
        </>
    );
};

export default PricingTable;
